import { ReactNode } from "react";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import Button from "../Button";
import SocialMediaLinks from "../SocialMediaLinks";
import { useTranslation } from "next-i18next";
import Icon from "../Icon";
import s from "./FourOhFour.module.scss";

type Props = {
  goHomeLink: string;
  searchLink: string;
  newsLink?: string;
  newsTitle?: string;
  contactLink: string;
  socialMediaCustomClassName?: string;
  locale: string;
  isCorporate: boolean;
  image?: ReactNode;
};

const FourOhFourUi = ({
  goHomeLink,
  searchLink,
  newsLink,
  newsTitle,
  contactLink,
  socialMediaCustomClassName,
  locale,
  isCorporate,
  image,
}: Props) => {
  const { t } = useTranslation();

  return (
    <section className={s.fourOhFour}>
      <div className={cn(s.inner, c.xWide)}>
        <div className={s.content}>
          <h1 className={s.title}>{t("404-title")}</h1>
          <h4 className={s.description}>{t("404-text")}</h4>
          <div className={s.buttons}>
            <Button href={goHomeLink} variant="cta">
              {t("go-to-home")}
            </Button>
            <Button href={searchLink} variant="default">
              {t("search")}
            </Button>
            <Button href={contactLink} variant="default">
              {t("contact-us")}
            </Button>
          </div>
          <SocialMediaLinks
            isCorporate={isCorporate}
            locale={locale}
            customClassName={socialMediaCustomClassName}
          />
          {newsLink && newsTitle && (
            <a href={newsLink} className={s.newsLink} title={newsTitle}>
              <span>{newsTitle}</span>
              <Icon
                icon="arrow-right"
                color={s.iconInverted}
                width={20}
                className={s.icon}
              />
            </a>
          )}
        </div>

        <div className={s.visual}>
          {image && (
            <div className={s.imageContainer}>
              <figure className={s.figure}> {image}</figure>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FourOhFourUi;
